import request from './request'
import store from "./store"

export default {
  login(email, pass, cb) {
    cb = arguments[arguments.length - 1]

    request.post("/login", { username: email, password: pass }, (res) => {
      if (res.success) {
        this.setUser(res.data)
        this.onChange(true)
        if (res.data.Administrator) {
          request.get('/update-required', null, result => {
            //view orange snackbar for update
            if (result.data == true) {
              store.dispatch("setUpdateSnackbar", true)
            }

          })
        }

      }

      if (cb) cb(res)
    })
  },

  logOut(cb) {
    this.onChange(false)
    request.delete('/logout', {}, (res) => {
      cb = arguments[arguments.length - 1]

      if (res.success) {
        this.deleteUser()
      }

      if (cb) cb(res)
    })
  },

  loggedIn() {
    return !!localStorage.user
  },

  onChange() { },

  getUser() {
    if (localStorage.user == null) {
      return null
    }

    return JSON.parse(localStorage.user)
  },
  getUserId() {
    const storedUser = JSON.parse(localStorage.user)
    if (localStorage.user == null) {
      return null
    }
    if (storedUser._id) {
      return storedUser._id
    }
    return storedUser.Id
  },

  setUser(user) {
    localStorage.user = JSON.stringify(user)
  },

  deleteUser() {
    delete localStorage.user
  },

  userIsAdministrator() {
    if (localStorage.user == null) {
      return false
    }

    return this.getUser().Administrator
  },
  userIsUserManager() {
    if (localStorage.user == null) {
      return false
    }

    return this.getUser().UserManager
  },

  userLanguage() {
    if (localStorage.user == null) {
      return ""
    }

    return this.getUser().Language
  },
  userToken() {
    const user = this.getUser();
    if (!user) {
      return null;
    }
    return user.Token;
  },
  userIsFirstLogin() {
    const user = this.getUser()
    if (!user) {
      return false
    }
    return user.IsFirstLogin
  }
}
