import auth from "../auth"
//initial state
const state = {
    userRoles: null,
    isUserUserManager: false,
    pageId: null,
    usersWithAccess: null

}
/* 0 = read
1 = write
2 = delete*/
//getters
const getters = {
    userRoles: state => state.userRoles,
    isUserUserManager: state => state.isUserUserManager,
    hasUserRoleForPageItem: (state) => (item) => {
        if (!state.userRoles) {
            return false
        }
        return state.userRoles.Roles.includes(item.role)
    },
}

//actions
const actions = {

    setUserManager({ commit }, bool) {
        commit('setUserManager', bool)
    },
    setUserRolesForPage({ commit }, usersWithAccess) {
        commit('setUserRolesForPage', usersWithAccess)
    },

    logoutReset({ commit }) {
        commit('logoutReset')
    }
}

//mutations
const mutations = {

    setUserManager(state, bool) {
        state.isUserUserManager = bool
    },
    setUserRolesForPage(state, usersWithAccess) {
        const userId = auth.getUserId()
        state.usersWithAccess = usersWithAccess
        state.userRoles = usersWithAccess.find(element => element.UserId.$oid == userId);
    },

    logoutReset(state) {
        state.userRoles = null
        state.isUserUserManager = null
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
