import request from "../request";
//initial state
const state = {
    updateSnackbar: false,
    isUpdatingVersion: false,
    isSuccessfullyUpdated: false,
    updateVersionSnackbar: false
}

//getters
const getters = {
    updateSnackbar: state => state.updateSnackbar,
    updateVersionSnackbar: state => state.updateVersionSnackbar,
    isUpdatingVersion: state => state.isUpdatingVersion,
    isSuccessfullyUpdated: state => state.isSuccessfullyUpdated
}

//actions
const actions = {
    setUpdateSnackbar({ commit }, bool) {
        commit('setUpdateSnackbar', bool)
    },
    updateVersion({ commit }) {
        commit('updateVersion')
    },
    setUpdateVersionSnackbar({ commit }, bool) {
        commit('setUpdateVersionSnackbar', bool)
    }

}

//mutations
const mutations = {
    setUpdateSnackbar(state, bool) {
        state.updateSnackbar = bool
    },
    setUpdateVersionSnackbar(state, bool) {
        state.isSuccessfullyUpdated = bool
    },
    updateVersion(state) {
        state.isUpdatingVersion = true
        request.post("/update", null, res => {
            state.isUpdatingVersion = false
            if (res.success) {
                state.updateSnackbar = false
                state.isSuccessfullyUpdated = true
            }
        })
    },


}

export default {
    state,
    getters,
    actions,
    mutations
}
